//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import ServiceAnalysisCard from "./ServiceAnalysisCard.vue";
import PaymentCard from "./PaymentCard";
import PaymentDialog from "@/common/components/Dialog/PaymentDialog";
export default {
  name: "CheckoutPayment",
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Genetic Data",
      active: false
    }, {
      number: 2,
      title: "Select Service & Analyst",
      active: false
    }, {
      number: 3,
      title: "Checkout and Payment",
      active: true
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }],
    isloading: false
  }),
  components: {
    ServiceAnalysisCard,
    PaymentCard,
    PaymentDialog
  },
  computed: {
    ...mapState({
      selectedGeneticData: state => state.geneticData.selectedData
    })
  },
  methods: {
    handleBack() {
      this.$router.push({
        name: "customer-request-analysis-service"
      });
    },
    showLoading() {
      this.isloading = true;
    }
  }
};